.ant-layout,
.ant-layout .ant-layout-sider .ant-menu {
  background-color: #fff;
}
aside {
  border-right: 1px solid #2490ff;
}
.ant-layout .ant-menu .ant-menu-item {
  color: #2490ff;
  font-weight: 500;
}
.ant-layout .ant-menu .ant-menu-item:hover {
  background-color: #e3f2ff;
  color: #2490ff;
}
.ant-layout .ant-menu .ant-menu-item.active,
.ant-layout .ant-layout-sider .ant-menu .ant-menu-item-selected.active {
  background-color: #2490ff;
  color: #fff;
}
.ant-layout .ant-table th {
  background-color: #fff;
  color: #2490ff;
  border-color: #2490ff;
  border-right: none;
  font-weight: bold;
  padding-left: 0 !important;
}
.ant-layout .ant-table td {
  padding-left: 0 !important;
}
.ant-layout .ant-table th::before {
  display: none;
}
.ant-layout .ant-table .ant-table-row:nth-child(even) {
  background-color: #fff;
}
.ant-tabs-nav-wrap {
  border-bottom: 5px solid #2490ff;
}
.ant-tabs-tab {
  padding: 15px 28px 6px;
  margin-left: 0 !important;
  font-weight: bold;
}
.ant-tabs-tab-active {
  border-radius: 30px 30px 0 0;
  background-color: #2490ff;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
label {
  font-weight: bold;
}
.ant-radio-group label,
.ant-checkbox-group label {
  font-weight: normal;
}
/* end of style css */